import { MouseEventHandler, useEffect, useState, createRef } from 'react';
import { delays } from '../../utils/sequence';
import './index.scss';
import { ArrowUpward, ArrowDownward, Delete } from '@material-ui/icons';
import { set } from 'lodash';
import { UbiverseNote } from '../../types';

interface NoteProp {
  nodeId: string;
  note: UbiverseNote[];
  intensity: number;
  positionX: number;
  editingMode: string;
  onIntensityUp: () => void;
  onIntensityDown: () => void;
  onDelete: () => void;
  onClientXMove: (clientX: number) => void;
  onIntensityChange: (intensity: number) => void;
}

const Note = ({ nodeId, note, intensity, positionX, editingMode, onIntensityUp, onIntensityDown, onDelete, onClientXMove, onIntensityChange }: NoteProp) => {
  const ref = createRef<HTMLDivElement>();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [_intensity, _setIntensity] = useState(0);
  const delay = delays[_intensity - 1] || 0;

  useEffect(() => {
    _setIntensity(intensity < 0 ? 0 : intensity > 10 ? 10 : intensity);
  }, [intensity]);

  return (
    <div
      className="component--note"
      style={{ left: `${positionX || 0}px` }}
      onMouseEnter={() => { setIsMouseOver(true); }}
      onMouseLeave={() => { setIsMouseOver(false); setIsMouseDown(false); }}
      onMouseDownCapture={(e) => { setIsMouseDown(true); e.stopPropagation(); }}
      onMouseUpCapture={( ) => { setIsMouseDown(false); }}
      onMouseMove={(e) => {
        if (isMouseOver && isMouseDown) {
          onClientXMove(e.clientX);
        }
      }}
      onDrag={(e) => { console.log(e, 'drag') }}
      onClick={(e) => {
        if (editingMode === 'delete') {
          onDelete();
        } else {
          const level = Math.round( (ref.current?.getClientRects()[0].bottom ? ref.current.getClientRects()[0].bottom - e.clientY : 0) / 16 )
          console.log(note, nodeId);
          if (note.findIndex(n => n.nodeId === nodeId) > 0) {
            const previousNote = note[note.findIndex(n => n.nodeId === nodeId) - 1];
            const currentNote = note[note.findIndex(n => n.nodeId === nodeId)];
            console.log(previousNote.timestamp, currentNote.timestamp, delays[level - 1], 'P / C / D')
            if (previousNote.timestamp + delays[level - 1] > currentNote.timestamp) {
              return;
            }
          }
          
          _setIntensity(level);
          onIntensityChange(level);
        }
      }}
      ref={ref}
    >
      <div className="component--note__delay" style={{ width: `${delay * 2}px` }}></div>
      <div className="component--note__base" style={{ height: `calc(${10 * _intensity}%)` }}></div>
    </div>
  );
}

export default Note;