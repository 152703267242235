import { useEffect, useState } from 'react';
import './index.scss';

import { UbiverseClient, UbiverseEventTopic__Trigger, UbiverseEventTopic__SetData, UbiverseEventTopic__SimStateChanged, UbiverseNodeType__Child } from '../../types';

import { SocketClient } from '../../services/socket-client';

interface UbiSimProps {
  socketClient: SocketClient;
}

const UbiSim = ({ socketClient }: UbiSimProps) => {
  const [simName, setSimName] = useState('');
  const [simState, setSimState] = useState<UbiverseClient>({
    id: '',
    type: UbiverseNodeType__Child,
    data: { label: '', intensity: 0 }
  });
  const [intensity, setIntensity] = useState(0);
  // const [isPlaying, setIsPlaying] = useState(false);

  // play
  // socketClient.subscribeEvent(topic.play.literal, () => {
  //   setIsPlaying(true);
  // });

  // // Pause
  // socketClient.subscribeEvent(topic.pause.literal, () => {
  //   setIsPlaying(false);
  // });

  // Trigger
  useEffect(() => {
    socketClient.subscribeEvent(UbiverseEventTopic__SetData, (payload) => {
      const { data } = payload;
      console.log('UbiSim:: Set Data', data);
    });

    const _interval = setInterval(() => {
      if (socketClient.getConnectionId()) {
        setSimState({ ...simState, id: socketClient.getConnectionId() })
        setSimName(socketClient.getConnectionId());
        clearInterval(_interval);
      }
    }, 50);
  }, [socketClient]);

  // Intensity Kickback
  useEffect(() => {
    console.log('UbiSim:: Intensity is set', intensity);
    setSimState({ ...simState, data: { ...simState.data, intensity } });
  }, [intensity]);

  useEffect(() => {
    setSimState( { ...simState, data: { ...simState.data, label: simName } })
  }, [simName]);

  useEffect(() => {
    console.log('UbiSim:: Update sim state - ', simState);
    socketClient.emitEvent(UbiverseEventTopic__SimStateChanged, simState);
  }, [simState]);

  return (
    <div className="page--ubi-sim">
      <div className="page--ubi-sim__container">
        <div className="page--ubi-sim__intensity-indicator">
          <input className="page--ubi-sim__label" type="text" value={simName} onChange={(e) => { setSimName(e.target.value) }} />
          <div className="page--ubi-sim__intensity-indicator__dot" style={{ opacity: 0.9 * (intensity / 128) + 0.1 }}></div>
          <p>Intensity: {intensity}</p>
        </div>
      </div>
    </div>
  )
}

export default UbiSim;