// Dependencies
import { memo, useEffect, useState, createRef } from 'react';
import { Handle, Position, Node, NodeProps } from '@xyflow/react';
import { Edit, Delete, ZoomOut, ZoomIn, ViewColumn } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { delays } from '../../../../utils/sequence';
import Note from '../../../Note';
import { convertSequenceToData } from '../../../../utils/sequence';
import { UbiverseEventTopic__SetData, UbiverseNote } from '../../../../types';
import './index.scss';

export type TimelineNodeData = {
  onClick: (topic: string, payload: any) => void;
};
type TimelineNodeProp = Node<TimelineNodeData>;
type EditingMode = 'edit' | 'delete';

export default memo(({ data, isConnectable }: NodeProps<TimelineNodeProp>) => {
  const [bpm, setBpm] = useState<number>(120);
  const [horizontalScale, setHorizontalScale] = useState<number>(2);
  const [isInEdit, setIsInEdit] = useState<boolean>(true);
  const [editingMode, setEditingMode] = useState<EditingMode>('edit');
  const [isSnap, setIsSnap] = useState<boolean>(true);
  const [quarterNote, setQuarterNote] = useState<number>(horizontalScale * (60/bpm) * 1000 / 50);
  const [note, setNote] = useState<UbiverseNote[]>([]);
  const [timelineLength, setTimelineLength] = useState<number>(105);
  const [timelineLengthPx, setTimelineLengthPx] = useState<number>(0);
  const refGraph = createRef<HTMLDivElement>();

  const graphNotesRef = createRef<HTMLDivElement>();

  const handleTimelineClick = (event: React.MouseEvent) => {
    if (!graphNotesRef.current) return;
    const mouseX = event.clientX - graphNotesRef.current.getBoundingClientRect().left;
    if (editingMode === 'edit') {
      if (isSnap) {
        console.log(mouseX, quarterNote);
      }
      if (isSnap && note.find((n) => { 
        return n.timestamp === Math.round((mouseX / horizontalScale) / quarterNote) * quarterNote 
          || (n.timestamp > Math.round((mouseX / horizontalScale) / quarterNote) * quarterNote) 
            && (n.timestamp - delays[n.intensity - 1] < Math.round((mouseX / horizontalScale) / quarterNote) * quarterNote)

      })) return;
      if (!isSnap && note.find(n => Math.abs(n.timestamp - mouseX / horizontalScale) < 5)) return;
      

      setNote([...note, {
        nodeId: uuidv4(),
        timestamp: isSnap ? Math.round((mouseX / horizontalScale) / quarterNote) * quarterNote : mouseX / horizontalScale,
        intensity: 5
      }].sort((a, b) => a.timestamp - b.timestamp));
    } else if (editingMode === 'delete') {
      console.log('delete');
      // note.forEach(n => console.log(n.time, mouseX, Math.abs(n.time - mouseX)));
      // setNote(note.filter(n => Math.abs(n.time - mouseX) > 5));
    }
  }

  const handleContainerHorizontalScroll = (event: React.UIEvent) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollWidth - target.clientWidth <= target.scrollLeft) {
      setTimeout(() => {
        setTimelineLength(timelineLength + 20);
      }, 300);
    }
  }

  useEffect(() => {
    setTimelineLengthPx(refGraph.current?.scrollWidth || 0);
  }, [refGraph]);

  useEffect(() => {
    setQuarterNote(horizontalScale * (60/bpm) * 1000 / 50);
  }, [horizontalScale, bpm]);

  return (
    <div className="node--timeline">
      <div className="node--timeline__edit">
        <div className="node--timeline__edit__actions">
          {isInEdit && <div className="node--timeline__edit__actions__wrapper">
            <button className={`small ${editingMode === 'edit' ? 'active' : ''} node--timeline__edit__action__button node--timeline__edit__actions__add`} onClick={() => setEditingMode('edit')}><Edit fontSize={'medium'} /></button>
            <button className={`small ${editingMode === 'delete' ? 'active' : ''} node--timeline__edit__action__button node--timeline__edit__actions__remove`} onClick={() => setEditingMode('delete')}><Delete fontSize={'medium'} /></button>
            <div className="divider"></div>
            <button className={`small ${isSnap ? 'active' : ''} node--timeline__edit__action__button`} onClick={() => setIsSnap(!isSnap)}><ViewColumn fontSize={'medium'} /></button>
            <div className="divider"></div>
            <button className="small node--timeline__edit__action__button" onClick={() => setHorizontalScale(horizontalScale - 0.1)}><ZoomOut fontSize={'medium'} /></button>
            <button className='small node--timeline__edit__action__button' onClick={() => setHorizontalScale(horizontalScale + 0.1)}><ZoomIn fontSize={'medium'} /></button>
          </div>}
        </div>
        <div>
          {!isInEdit && <p className="inverse">BPM: {bpm}</p>}
          {isInEdit && <div className="node--timeline__bpm-fields">
            <label className="node--timeline__bpm-fields__label" htmlFor="bpm">BPM:</label>
            <input id="bpm" type="number" value={bpm} readOnly onMouseDown={() => {console.log('handle mousedown')}} />
          </div>}
        </div>
        {/* <button onClick={() => {
          setIsInEdit(!isInEdit);
          setEditingMode('');
        }}>{isInEdit ? 'Done' : 'Edit'}</button> */}
      </div>
      <div className="node--timeline__graph__container" onScroll={handleContainerHorizontalScroll}>
        <div 
          ref={refGraph}
          className="node--timeline__graph"
          style={{ width: `${timelineLength * horizontalScale}%` }}
          onClick={handleTimelineClick}
        >
          <div className="node--timeline__graph__grid" style={{ gridTemplateColumns: `repeat(${(timelineLengthPx / quarterNote) - (timelineLengthPx / quarterNote)%4}, ${quarterNote}px)` }}>
            {Array.from({ length: 10 * ((timelineLengthPx / quarterNote) - (timelineLengthPx / quarterNote) % 4) }).map((_, i) => {
              return <div className={`node--timeline__graph__grid-cell ${i % 4 === 0 ? 'major' : ''}`} style={{ width: `${quarterNote}px` }}>
                {i % 4 === 0 && i < ((timelineLengthPx / quarterNote) - (timelineLengthPx / quarterNote) % 4) && <div className="node--timeline__graph__grid-cell__label">{i / 4 + 1}</div>}
              </div>
            })}
          </div>

          {/* Notes */}
          <div className="node--timeline__graph__notes" ref={graphNotesRef}>
            {note.map((n, i) => {
              return (
                <Note
                  nodeId={n.nodeId}
                  note={note}
                  editingMode={editingMode}
                  intensity={n.intensity}
                  positionX={n.timestamp * horizontalScale}
                  onIntensityChange={(intensity) => {
                    setNote(note.map((nn, j) => {
                      return n.nodeId === nn.nodeId ? { ...nn, intensity } : nn
                    }))
                  }}
                  onIntensityDown={() => {
                    setNote(note.map((nn, j) => {
                      return n.nodeId === nn.nodeId ? { ...nn, intensity: nn.intensity - 1 > 10 ? 10 : nn.intensity - 1 < 0 ? 0 : nn.intensity - 1 } : nn
                    }))
                  }}
                  onIntensityUp={() => {
                    setNote(note.map((nn, j) => {
                      return n.nodeId === nn.nodeId ? { ...nn, intensity: nn.intensity + 1 > 10 ? 10 : nn.intensity + 1 < 0 ? 0 : nn.intensity + 1 } : nn
                    }));
                  }}
                  onDelete={() => {
                    setNote(note.filter((nn) => nn.nodeId !== n.nodeId ));
                  }}
                  onClientXMove={(clientX) => {
                    const mouseX = clientX - (graphNotesRef.current ? graphNotesRef.current.getBoundingClientRect().left : 0);
                    setNote(note.map((nn, j) => {
                      return n.nodeId === nn.nodeId ? { ...nn, time: mouseX * horizontalScale } : nn
                    }))
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div>
        <button onClick={() => {
          const timelineData = convertSequenceToData(JSON.stringify( _.sortBy(note.map(n => ({ intensity: n.intensity, timestamp: n.timestamp })), (data) => { return data.timestamp }) ));
          // console.log(timelineData, 'timelineData');
          data.onClick(UbiverseEventTopic__SetData, timelineData)
        }}>Send</button>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
});