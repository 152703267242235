import { memo, useState } from 'react';
import { Handle, Position, Node, NodeProps } from '@xyflow/react';

import './index.scss';

export type FingerNodeData = {
  label: string;
  intensity: number;
};
type FingerNodeProp = Node<FingerNodeData>;

export default memo(({ data, isConnectable }: NodeProps<FingerNodeProp>) => {
  return (
    <div className="node--finger">
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
      />
      <div>
        {<p className="inverse">{data.label || 'unknown finger'}</p>}
        {<p className="inverse">{data.intensity || 0}</p>}
      </div>
    </div>
  );
});