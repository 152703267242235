import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';

// Socket.io
import { SocketClient } from './services/socket-client';

// Pages
import Dashboard from './pages/Dashboard';
import UbiSim from './pages/UbiSim';

const socketClient = process.env.NODE_ENV === 'production'
  ? new SocketClient()
  : new SocketClient('http://localhost:3001');

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard socketClient={socketClient} />} />
          <Route path="/sim" element={<UbiSim socketClient={socketClient} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
