// Dependencies
import { useEffect, useState } from 'react';

// Types and Interfaces
import { SocketClient } from '../../services/socket-client';

// Types
import { UbiverseEventTopic, UbiverseEventTopic__SessionListChanged, UbiverseEventTopic__SimStateChanged, UbiverseClient } from '../../types';

// Styles
import './index.scss';

// Components
import Ubiverse from '../../components/Ubiverse';

// Interfaces
interface DashboardProps {
  socketClient: SocketClient;
}

// Dashboard component
const Dashboard = ({ socketClient }: DashboardProps) => {
  const [sessionList, setNodeList] = useState<UbiverseClient[]>([]);

  const handleNodeListChanged = (payload: UbiverseClient[]) => {
    setNodeList(payload.map(p => {
      return sessionList.find(n => n.id === p.id)
        ? { ...sessionList.find(n => n.id === p.id), ...p }
        : p
      ;
    }));
  };

  const handleSimStateChanged = (payload: UbiverseClient) => {
    if (!payload.id) return;
    setNodeList(
      sessionList.find(n => n.id === payload.id)
      ? [ ...sessionList.filter(n => n.id !== payload.id), { ...sessionList.find(n => n.id === payload.id), ...payload } ]
      : [ ...sessionList, payload ]
    );
  };

  useEffect(() => {
    socketClient.subscribeEvent(UbiverseEventTopic__SessionListChanged, handleNodeListChanged);  
    socketClient.subscribeEvent(UbiverseEventTopic__SimStateChanged, handleSimStateChanged);
  }, [sessionList]);

  return (
    <div className="page--dashboard">
      <div className="page--dashboard__container">
        <Ubiverse
          sessionList={sessionList}
          emitEvent={(topic: UbiverseEventTopic, payload: any) => socketClient.emitEvent(topic, payload)}
        />
      </div>
      <div className="page--dashboard__sim-launcher">
        <a href="/sim" target="_blank" rel="noreferrer">Launch UbiSim</a>
      </div>
    </div>
  )
}

export default Dashboard;