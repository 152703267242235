import io, { Socket } from 'socket.io-client';

import {
  UbiverseNodeType__Child,
  UbiverseNodeType__Parent,
  UbiverseEventTopic,
} from '../types';

export class SocketClient {
  socket: Socket;

  constructor(server = '/') {
    this.socket = io(
      server,
      process.env.NODE_ENV === 'production'
        ? { path: '/sx/socket.io', query: { type: window.document.location.pathname === '/sim' ? UbiverseNodeType__Child : UbiverseNodeType__Parent }}
        : { query: { type: window.document.location.pathname === '/sim' ? UbiverseNodeType__Child : UbiverseNodeType__Parent }}
    )

    this.socket.on('connect', () => {
      console.log('This client is connected to the server.');
    });

    this.socket.on('disconnect', () => {
      console.log('This client is disconnected from the server.');
    });
  }

  subscribeEvent(topic: UbiverseEventTopic, callback: (...args: any) => void): void {
    this.socket.removeAllListeners(topic);
    this.socket.on(topic, callback);
  }

  emitEvent(topic: UbiverseEventTopic, payload: any = null): void {
    this.socket.emit(topic, payload);
  }

  getConnectionId(): string {
    return this.socket.id || '';
  }
}