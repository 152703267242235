import { ChangeEvent, memo, useState } from 'react';
import { Handle, Position, Node, NodeProps } from '@xyflow/react';

import { UbiverseEventTopic, UbiverseEventTopicList, UbiverseEventTopic__Play } from '../../../../types';

import './index.scss';

export type ButtonNodeData = {
  label: string;
  onClick: (topic: UbiverseEventTopic, payload: any) => void;
};
type ButtonNodeProp = Node<ButtonNodeData>;

export default memo(({ data, isConnectable }: NodeProps<ButtonNodeProp>) => {
  const [selectedTopic, setSelectedTopic] = useState<UbiverseEventTopic>(UbiverseEventTopic__Play);
  const [payload, setPayload] = useState<string>('');
  return (
    <div className="node--button">
      <div>
        <label htmlFor="topic">Topic:</label>
        <br />
        <select name="topic" onChange={(e: ChangeEvent) => { setSelectedTopic((e.target as HTMLSelectElement).value as UbiverseEventTopic) }}>
          {Object.keys(UbiverseEventTopicList).map((t, i) => {
            return <option value={t} key={i}>{t}</option>
          })}
        </select>
      </div>
      <br />
      <div>
        <label htmlFor="payload">Payload:</label>
        <br />
        <textarea name="payload" onChange={(e) => { setPayload(e.target.value) }} />
      </div>
      <br />
      <button onClick={() => data.onClick(selectedTopic, payload)}>{data.label}</button>
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
      />
    </div>
  );
});