import { UbiverseSequenceData } from '../types';

export const delays = [ 8, 9, 10, 11, 12, 14, 16, 22, 30, 40 ];

export function convertPayloadToTrigger(payload: UbiverseSequenceData) {
  let _intensity = Math.floor(payload.intensity);
  if (_intensity < 1) _intensity = 1;
  if (_intensity > 10) _intensity = 10;

  const delay = delays[_intensity - 1];
  const timestamp = Math.round(payload.timestamp);

  return [timestamp - delay, timestamp];
}

export function convertSequenceToData(sequence: UbiverseSequenceData[] | string, offsetTime: number = 0) {
  let data: number[] = [];
  const _sequence = typeof(sequence) === 'string' ? JSON.parse(sequence) as UbiverseSequenceData[] : sequence;
  _sequence.forEach((t: UbiverseSequenceData) => {
    data = [...data, ...convertPayloadToTrigger({ intensity: t.intensity, timestamp: t.timestamp + offsetTime})];
  });
  return data;
}