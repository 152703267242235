// Deps
import { useCallback, useEffect, useState } from 'react';
import {
  ReactFlow,
  Controls,
  Background,
  addEdge,
  Node,
  Edge,
  BackgroundVariant,
  OnNodesChange,
  applyNodeChanges,
  applyEdgeChanges,
  OnEdgesChange,
  OnConnect,
} from '@xyflow/react';
import * as _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

// Consts
import { UbiverseEventTopic, UbiverseClient, UbiverseNodeType__Child } from '../../types';

// Styles
import './index.scss';
import '@xyflow/react/dist/style.css';

// Components
// import Ubi from '../Ubi';
import ButtonNode from './nodes/ButtonNode';
import FingerNode from './nodes/FingerNode';
import TimelineNode from './nodes/TimelineNode';

const timelineNodeId = `timeline--${uuidv4()}`;

const customNodeTypes = {
  buttonNode: ButtonNode,
  fingerNode: FingerNode,
  timelineNode: TimelineNode
};

interface UbiverseProps {
  sessionList: UbiverseClient[];
  emitEvent: (topic: UbiverseEventTopic, payload: any) => void;
}

const Ubiverse = ({ sessionList, emitEvent }: UbiverseProps) => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);

  const onNodesChange: OnNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds))
  }, [setNodes]);

  const onEdgesChange: OnEdgesChange = useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds))
  }, [setEdges]);

  const onConnect: OnConnect = useCallback((connection) => {
    setEdges((eds) => addEdge(connection, eds))
  }, [setEdges]);

  // New Nodes in network
  useEffect(() => {
    const activeSessionIds = [...sessionList.filter(s => s.type === UbiverseNodeType__Child), { id : timelineNodeId, type: UbiverseNodeType__Child }];

    const onButtonEventClick = (selectedTopic: UbiverseEventTopic, payload: any) => {
      edges.forEach(e => {
        emitEvent(selectedTopic, { targetId: e.target, data: payload });
      });
    }

    const nodeList = activeSessionIds.map((_session, i) => {
      return nodes.find(n => n.id === _session.id)
        ? (_session.id === timelineNodeId
          ? {
            ...nodes.find(n => n.id === _session.id) as Node,
            data: {...(nodes.find(n => n.id === _session.id) as Node).data, onClick: onButtonEventClick }
          }
          : {
            ...nodes.find(n => n.id === _session.id) as Node,
            data: { ...nodes.find(n => n.id === _session.id)?.data, label: _session?.data?.label || _session.id }
          }
        )
        : (_session.id === timelineNodeId
          ? {
            id: _session.id,
            position: { x: 12, y: 220 },
            type: 'timelineNode',
            data: { label: 'Timeline', onClick: onButtonEventClick },
            deletable: false,
            isConnectable: true
          } as Node
          : {
            id: _session.id,
            position: { x: (i + 50)*12, y: (i + 20)*12 },
            type: 'fingerNode',
            data: { label: _session?.data?.label || _session.id },
            deletable: false,
            isConnectable: true
          } as Node)
      });

      if (!_.isEqual(nodeList, nodes)) {
      setNodes(nodeList);
    }
  }, [edges, sessionList]);

  return (
    <div className="component--ubiverse">
      <div className="component--ubiverse__container">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          snapToGrid={true}
          nodeTypes={customNodeTypes}
        >
          <Controls />
          <Background variant={'dots' as BackgroundVariant} gap={12} size={0.5} />
        </ReactFlow>
      </div>
    </div>
  );
}

export default Ubiverse;